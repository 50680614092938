*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Helvetica Neue LT Std, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --screen-width: 100vw;
  --current-screen: 100vw;
}

@media (width >= 640px) {
  :root {
    --current-screen: 640px;
  }
}

@media (width >= 768px) {
  :root {
    --current-screen: 768px;
  }
}

@media (width >= 1024px) {
  :root {
    --current-screen: 1024px;
  }
}

@media (width >= 1280px) {
  :root {
    --current-screen: 1280px;
  }
}

@media (width >= 1440px) {
  :root {
    --current-screen: 1440px;
  }
}

@media (width >= 1536px) {
  :root {
    --current-screen: 1536px;
  }
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

:is(::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field) {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.form-input, .form-textarea, .form-select, .form-multiselect {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-input:focus, .form-textarea:focus, .form-select:focus, .form-multiselect:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

.form-input::placeholder, .form-textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

.form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.form-input::-webkit-date-and-time-value {
  min-height: 1.5em;
}

:is(.form-input::-webkit-datetime-edit, .form-input::-webkit-datetime-edit-year-field, .form-input::-webkit-datetime-edit-month-field, .form-input::-webkit-datetime-edit-day-field, .form-input::-webkit-datetime-edit-hour-field, .form-input::-webkit-datetime-edit-minute-field, .form-input::-webkit-datetime-edit-second-field, .form-input::-webkit-datetime-edit-millisecond-field, .form-input::-webkit-datetime-edit-meridiem-field) {
  padding-top: 0;
  padding-bottom: 0;
}

.form-checkbox, .form-radio {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

.form-checkbox {
  border-radius: 0;
}

.form-checkbox:focus, .form-radio:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

.form-checkbox:checked, .form-radio:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.form-checkbox:checked:hover, .form-checkbox:checked:focus, .form-radio:checked:hover, .form-radio:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

.form-checkbox:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

.form-checkbox:indeterminate:hover, .form-checkbox:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

.site-wrapper {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (width >= 1024px) {
  .site-wrapper {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }
}

.nav-link {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.nav-link:hover {
  --tw-text-opacity: 1;
  color: rgb(197 208 24 / var(--tw-text-opacity));
}

.nav-link-mobile {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 700;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.nav-link-mobile:hover {
  --tw-text-opacity: 1;
  color: rgb(197 208 24 / var(--tw-text-opacity));
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-28 {
  top: 7rem;
}

.top-3 {
  top: .75rem;
}

.top-48 {
  top: 12rem;
}

.-z-10 {
  z-index: -10;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-\[999\] {
  z-index: 999;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-start-1 {
  grid-column-start: 1;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mt-52 {
  margin-top: -13rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-96 {
  height: 24rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-\[1000px\] {
  min-height: 1000px;
}

.min-h-\[100\] {
  min-height: 100px;
}

.min-h-\[115\] {
  min-height: 115px;
}

.min-h-\[350\] {
  min-height: 350px;
}

.min-h-\[400\] {
  min-height: 400px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-20 {
  width: 5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.shrink-0 {
  flex-shrink: 0;
}

.origin-\[0\] {
  transform-origin: 0;
}

.-translate-y-6 {
  --tw-translate-y: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-start {
  scroll-snap-align: start;
}

.scroll-pl-6 {
  scroll-padding-left: 1.5rem;
}

.appearance-none {
  appearance: none;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.content-center {
  align-content: center;
}

.content-end {
  align-content: flex-end;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-x-auto {
  overflow-x: auto;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-none {
  border-radius: 0;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-\[\#606060\] {
  --tw-border-opacity: 1;
  border-color: rgb(96 96 96 / var(--tw-border-opacity));
}

.border-green {
  --tw-border-opacity: 1;
  border-color: rgb(197 208 24 / var(--tw-border-opacity));
}

.border-opacity-20 {
  --tw-border-opacity: .2;
}

.bg-background-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(0 81 126 / var(--tw-bg-opacity));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(197 208 24 / var(--tw-bg-opacity));
}

.bg-light-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(225 225 225 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-black {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-\[position\:center_bottom_4rem\] {
  background-position: center bottom 4rem;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-green {
  fill: #c5d018;
}

.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 2.5rem;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-base {
  font-size: 1rem;
}

.text-sm {
  font-size: .875rem;
}

.text-xl {
  font-size: 1.5rem;
}

.text-xs {
  font-size: .625rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[1\.1\] {
  line-height: 1.1;
}

.leading-\[1\], .leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.text-green {
  --tw-text-opacity: 1;
  color: rgb(197 208 24 / var(--tw-text-opacity));
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgb(96 96 96 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.accent-green {
  accent-color: #c5d018;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.bg-blend-overlay {
  background-blend-mode: overlay;
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.br-container {
  margin-right: calc((var(--screen-width)  - var(--current-screen)) / -2 - var(--container-padding));
  padding-right: calc((var(--screen-width)  - var(--current-screen)) / 2 + var(--container-padding));
}

.clip-path-leading {
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
}

.clip-path-untapped {
  clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
}

.clip-path-racer {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
}

@font-face {
  font-display: swap;
  font-family: Helvetica Neue LT Std;
  src: url("helveticaneueltstd-roman.fed34b80.woff2") format("woff2"), url("helveticaneueltstd-roman.2858a96f.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: Helvetica Neue LT Std;
  src: url("helveticaneueltstd-md.9036a6f6.woff2") format("woff2"), url("helveticaneueltstd-md.1bfa85d3.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: Helvetica Neue LT Std;
  src: url("helveticaneueltstd-bd.518ffe4c.woff2") format("woff2"), url("helveticaneueltstd-bd.135b152e.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: Helvetica Neue LT Std;
  src: url("helveticaneueltstd-blkex.c552fb6a.woff2") format("woff2"), url("helveticaneueltstd-blkex.5db92e74.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: HelveticaNeueLT Arabic;
  src: url("HelveticaNeueLTArabic-Roman.4748d5be.woff2") format("woff2"), url("HelveticaNeueLTArabic-Roman.c2ceb9a2.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.font-ar {
  font-family: HelveticaNeueLT Arabic;
}

main {
  max-width: 1920px;
  margin: 0 auto;
}

.gallery-item {
  clip-path: polygon(0 0, 100% 0, 100% 92%, 89% 100%, 0 100%);
}

.no-scroll {
  position: fixed;
  overflow: hidden;
}

select option {
  background: #23aa4a !important;
}

.slick-slide {
  margin: 0 20px;
}

.slick-list {
  margin: 0 -20px;
  padding: 0 10% 0 0 !important;
}

#untapped-slider .slick-list {
  padding: 0 40% 0 0 !important;
}

.slick-arrow:hover {
  cursor: pointer;
}

.slick-disabled.slick-arrow {
  cursor: not-allowed;
}

.slick-arrow svg {
  fill: green;
  stroke: #00f;
}

.slick-disabled.slick-arrow.slick-left {
  background-image: url("arrow-left.1376cbad.svg") !important;
}

.slick-disabled.slick-arrow.slick-right {
  background-image: url("arrow-right.070d37d8.svg") !important;
}

.news-item > article:before {
  content: "";
  z-index: 1;
  border-right: 1px solid #60606033;
  width: 66px;
  height: 66px;
  position: absolute;
  bottom: 13px;
  right: 6px;
  transform: rotate(45deg);
}

.hamburger {
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 15px;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: inline-block;
  overflow: visible;
}

.hamburger:hover, .hamburger.is-active:hover {
  opacity: .7;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:before, .hamburger.is-active .hamburger-inner:after {
  background-color: #c5d018;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  margin-top: -2px;
  display: block;
  top: 50%;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  background-color: #c5d018;
  border-radius: 4px;
  width: 40px;
  height: 4px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.hamburger-inner:before, .hamburger-inner:after {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic .hamburger-inner:before {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, left .125s ease-in .175s;
  left: 0;
}

.hamburger--emphatic .hamburger-inner:after {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, right .125s ease-in .175s;
  top: 10px;
  right: 0;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: #0000 !important;
}

.hamburger--emphatic.is-active .hamburger-inner:before {
  transition: left .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: -80px;
  left: -80px;
  transform: translate3d(80px, 80px, 0)rotate(45deg);
}

.hamburger--emphatic.is-active .hamburger-inner:after {
  transition: right .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: -80px;
  right: -80px;
  transform: translate3d(-80px, 80px, 0)rotate(-45deg);
}

.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic-r .hamburger-inner:before {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, left .125s ease-in .175s;
  left: 0;
}

.hamburger--emphatic-r .hamburger-inner:after {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, right .125s ease-in .175s;
  top: 10px;
  right: 0;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: #0000 !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  transition: left .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: 80px;
  left: -80px;
  transform: translate3d(80px, -80px, 0)rotate(-45deg);
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  transition: right .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: 80px;
  right: -80px;
  transform: translate3d(-80px, -80px, 0)rotate(45deg);
}

#custom-carousel::-webkit-scrollbar {
  width: 10px;
}

#custom-carousel::-webkit-scrollbar-track {
  background: #fff padding-box content-box;
  border: 7px solid #0000;
}

#custom-carousel::-webkit-scrollbar-thumb {
  background: #c5d018;
  border: 1px solid #c5d018;
  width: 324px;
}

@media (width >= 1024px) {
  .footer-path {
    clip-path: polygon(71% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
  }
}

@media (width <= 820px) {
  #leading-the-field {
    background-position: bottom;
    background-image: url("bg-mobile@2x.88cba05e.webp") !important;
  }
}

@media (width <= 1024px) {
  .slick-list {
    padding: 0 !important;
  }
}

.select2-container {
  appearance: none !important;
  --tw-border-opacity: 1 !important;
  border-width: 0 0 1px !important;
  border-color: rgb(197 208 24 / var(--tw-border-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  background-color: #0000 !important;
  border-radius: 0 !important;
  width: 100% !important;
  padding-bottom: .5rem !important;
}

.select2-container--default .select2-selection--single {
  background-color: #0000 !important;
  border-width: 0 !important;
  border-radius: 0 !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 1rem;
  color: #fff !important;
  padding-left: 0 !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option[aria-selected="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(197 208 24 / var(--tw-bg-opacity)) !important;
}

.select2-results__option {
  --tw-border-opacity: 1 !important;
  border-bottom-width: 1px !important;
  border-color: rgb(197 208 24 / var(--tw-border-opacity)) !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #c5d018 #0000 #0000 !important;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: #0000 #0000 #c5d018 !important;
}

.select2-container.select2-container--open {
  border: none !important;
}

.select2-dropdown {
  z-index: 50;
  float: left;
  box-sizing: border-box;
  --tw-bg-opacity: 1;
  background-color: rgb(197 208 24 / var(--tw-bg-opacity));
  border-style: solid;
  border-width: 0;
  width: auto;
  display: block;
  position: absolute;
}

.select2-results {
  --tw-bg-opacity: 1;
  background-color: rgb(96 96 96 / var(--tw-bg-opacity));
}

.select2-results__option {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.select2-results__option--highlighted {
  background-color: #adff2f;
}

@media (width >= 1536px) {
  #slider-container {
    max-width: 1440px !important;
  }

  #graphics {
    margin-left: 6rem;
  }
}

@media (width >= 1920px) {
  #slider-container {
    max-width: 1536px !important;
  }

  #graphics {
    margin-left: 15rem;
  }
}

@media (width >= 1536px) {
  .\33 xl\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .\33 xl\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .\33 xl\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .\33 xl\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .\33 xl\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1440px) {
    .\33 xl\:container {
      max-width: 1440px;
    }
  }

  @media (width >= 1536px) {
    .\33 xl\:container {
      max-width: 1536px;
    }
  }
}

.hover\:bg-green:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(197 208 24 / var(--tw-bg-opacity));
}

.hover\:fill-blue:hover {
  fill: #00517e;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:border-\[\#C5D018\]:focus {
  --tw-border-opacity: 1;
  border-color: rgb(197 208 24 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:left-0 {
  left: 0;
}

.peer:focus ~ .peer-focus\:-translate-y-6 {
  --tw-translate-y: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:font-medium {
  font-weight: 500;
}

@media (width >= 640px) {
  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:text-center {
    text-align: center;
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:-left-\[6rem\] {
    left: -6rem;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-\[12rem\] {
    top: 12rem;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-start-5 {
    grid-column-start: 5;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:ml-\[20px\] {
    margin-left: 20px;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:mr-auto {
    margin-right: auto;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-screen {
    height: 100vh;
  }

  .md\:max-h-\[520\] {
    max-height: 520px;
  }

  .md\:min-h-\[750\] {
    min-height: 750px;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:min-w-\[300\] {
    min-width: 300px;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-5 {
    gap: 1.25rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:object-contain {
    object-fit: contain;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pl-12 {
    padding-left: 3rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pt-28 {
    padding-top: 7rem;
  }

  .md\:pt-44 {
    padding-top: 11rem;
  }

  .md\:pt-48 {
    padding-top: 12rem;
  }

  .md\:pt-56 {
    padding-top: 14rem;
  }

  .md\:text-2xl {
    font-size: 2.5rem;
  }

  .md\:text-3xl {
    font-size: 3.25rem;
  }

  .md\:text-4xl {
    font-size: 3.5rem;
  }

  .md\:text-xl {
    font-size: 1.5rem;
  }

  .md\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .md\:clip-path-leading-lg {
    clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 100%);
  }
}

@media (width >= 1024px) {
  .lg\:top-\[18rem\] {
    top: 18rem;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-4 {
    grid-column-start: 4;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:-ml-\[4\.5rem\] {
    margin-left: -4.5rem;
  }

  .lg\:-mt-44 {
    margin-top: -11rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:max-h-\[690\] {
    max-height: 690px;
  }

  .lg\:min-h-\[100\] {
    min-height: 100px;
  }

  .lg\:min-h-\[800px\] {
    min-height: 800px;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:bg-left {
    background-position: 0;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:pb-28 {
    padding-bottom: 7rem;
  }

  .lg\:pb-36 {
    padding-bottom: 9rem;
  }

  .lg\:pt-28 {
    padding-top: 7rem;
  }

  .lg\:pt-56 {
    padding-top: 14rem;
  }

  .lg\:text-2xl {
    font-size: 2.5rem;
  }

  .lg\:text-3xl {
    font-size: 3.25rem;
  }

  .lg\:text-4xl {
    font-size: 3.5rem;
  }

  .lg\:text-5xl {
    font-size: 5rem;
  }

  .lg\:text-base {
    font-size: 1rem;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:clip-path-untapped-lg {
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }
}

@media (width >= 1280px) {
  .xl\:-ml-\[2\.5rem\] {
    margin-left: -2.5rem;
  }

  .xl\:-mt-20 {
    margin-top: -5rem;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:min-h-\[132\] {
    min-height: 132px;
  }

  .xl\:min-h-\[360\] {
    min-height: 360px;
  }

  .xl\:items-end {
    align-items: flex-end;
  }

  .xl\:bg-fixed {
    background-attachment: fixed;
  }

  .xl\:text-3xl {
    font-size: 3.25rem;
  }
}

@media (width >= 1440px) {
  .\32 xl\:top-52 {
    top: 13rem;
  }

  .\32 xl\:-ml-8 {
    margin-left: -2rem;
  }

  .\32 xl\:min-h-\[115\] {
    min-height: 115px;
  }

  .\32 xl\:min-h-\[400\] {
    min-height: 400px;
  }

  .\32 xl\:bg-\[center_bottom_calc\(2rem\)\] {
    background-position: center bottom 2rem;
  }
}

@media (width >= 1536px) {
  .\33 xl\:col-start-1 {
    grid-column-start: 1;
  }

  .\33 xl\:col-start-5 {
    grid-column-start: 5;
  }

  .\33 xl\:max-h-\[640\] {
    max-height: 640px;
  }

  .\33 xl\:min-h-\[1100px\] {
    min-height: 1100px;
  }
}

/*# sourceMappingURL=index.1f6311ed.css.map */
